<template>
  <div class="ImportExaminee">
    <el-dialog
      title="批量修改考生"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="669px"
      :close-on-click-modal="false"
    >
      <div class="import-box">
        <div style="height: 425px" class="upload-box">
          <span class="export-box-go" @click="goBack">
            <i class="el-icon-back"></i>
            返回向导页
          </span>
          <el-steps
            style="height: 360px"
            direction="vertical"
            :active="active"
            finish-status="success"
          >
            <el-step title="上传表格">
              <template slot="description">
                <template v-if="faultTwo">
                  <p class="title">
                    <img src="@/assets/icon-img/error.svg" alt="" />
                    {{ faultTwoMsg }}
                  </p>
                </template>
                <template v-else>
                  <p v-if="fileList.length == 0" class="title title-tips">
                    待上传
                  </p>
                  <p v-else class="title">
                    <img src="@/assets/icon-img/succ.svg" alt="" />
                    {{ fileList[0].name }}
                  </p>
                </template>

                <el-upload
                  ref="upload"
                  :show-file-list="false"
                  :auto-upload="false"
                  class="upload-demo"
                  :action="examStudentImportURL"
                  :headers="uploadHeaders"
                  :on-success="handleSuccess"
                  :on-change="handleChange"
                  :on-error="handleError"
                  accept=".xlsx,.xls"
                  :file-list="fileList"
                >
                  <el-button type="primary">
                    <template v-if="fileList.length == 0"> 选择文件 </template>
                    <template v-else> 更换文件 </template>
                  </el-button>
                </el-upload>
              </template>
            </el-step>
            <el-step title="导入考生" description="">
              <template slot="description">
                <template v-if="faultThree">
                  <p class="title">
                    <img src="@/assets/icon-img/error.svg" alt="" />
                    {{ message }}
                  </p>
                </template>
                <template v-else>
                  <p v-if="unploadSucc" class="title">
                    <img src="@/assets/icon-img/succ.svg" alt="" />
                    已录入
                  </p>
                  <p v-else class="title title-tips">待导入</p>
                </template>
                <template v-if="errorUrl">
                  <el-button type="danger" @click="downloadError()">
                    下载失败原因
                  </el-button>
                </template>
                <template v-else>
                  <el-button
                    v-if="unploadSucc"
                    type="primary"
                    @click="dialogVisible = false"
                  >
                    完 成
                  </el-button>
                  <el-button
                    v-else
                    type="primary"
                    :disabled="fileList.length == 0 || faultThree"
                    :loading="btnLoading"
                    @click="uploadFile()"
                  >
                    提 交
                  </el-button>
                </template>
              </template>
            </el-step>
          </el-steps>
        </div>
        <div class="tips">
          <p style="color: #f56c6b">【注意】</p>
          <p>
            1、请先使用导出功能导出需要修改的考生，然后在Excel中修改考生信息，使用批量修改功能导入Excel修改。
          </p>
          <p>2、考号务必与系统内保持一致；</p>
          <p>3、班级名称务必统一，如“1”和“01”将会被认为是两个不同的班级。</p>
          <p>4、首选科目必须为物理或历史其中一科，漏填会导致表格无法导入；</p>
          <p>5、再选科目务必填写两科，漏填或科目相同均会导致表格无法导入；</p>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">
            确 定
          </el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fileDownloadByUrl } from "@/core/util/util";
import { getStore } from "@/core/util/store";
export default {
  name: "ImportExaminee",
  components: {},
  data() {
    return {
      errorUrl: "",
      dialogVisible: false,
      btnLoading: false,
      active: 0,
      fileList: [],
      faultOne: false,
      faultTwoMsg: "",
      faultTwo: false,
      faultThree: false,
      faultFour: false,
      unploadSucc: false,
      message: "",
      //考生导入start
      examStudentImportURL: "",

      uploadHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
    };
  },
  created() {
    this.examStudentImportURL =
      "/exam/examstudent/modify/" + this.$route.query.examId;
    // this.examStudentImportURL =
    //   "/exam/examstudent/import/" + this.$route.query.examId;
    let userInfo = getStore({ name: "userInfo" });
    this.$set(this.uploadHeaders, "SCHOOL-ID", userInfo.schoolId);
  },
  methods: {
    handleClose(done) {
      this.$parent.$refs.ImportWizard.dialogVisible = true;
      done();
    },
    // 下载失败原因
    downloadError() {
      fileDownloadByUrl(this.errorUrl);
    },
    uploadFile() {
      this.$refs.upload.submit();
    },
    // 上传成功
    handleSuccess(res) {
      this.btnLoading = false;
      this.errorUrl = "";
      if (res.code == 1) {
        this.message = res.data.message || "文件内容有误。";
        this.faultThree = true;
      }
      if (res.code == 0) {
        let message = res.data.message;
        if (message && message.indexOf("http") === 0) {
          this.message = "导入失败";
          this.faultThree = true;
          this.errorUrl = message;
          // console.log(this.faultThree);
        } else {
          this.faultThree = false;
          this.unploadSucc = true;
          this.$parent.count = res.data.count;
          setTimeout(() => {
            this.$parent.getDataList(1);
            this.$parent.getclassandexamroom();
          }, 2000);
        }
      }
    },
    // 上传错误
    handleError(res) {
      let error = JSON.parse(res.message);
      this.message = error.msg;
      if (error.msg.length > 30) {
        this.message = "文件内容有误。";
      }
      this.faultThree = true;
    },
    // 文件上传
    handleChange(file) {
      if (!this.indexUpLoad || this.indexUpLoad.uid != file.uid) {
        this.indexUpLoad = file;
        let size = 10 * 1024 * 1024;
        if (size < file.size) {
          this.fileList = [];
          this.faultTwoMsg = "上传失败：文件大小过大，请选择小于10MB的文件。";
          this.faultTwo = true;
          this.active = 2;
          return;
        }
        if (!(file.name.endsWith(".xlsx") || file.name.endsWith(".xls"))) {
          // this.$message.error("只能上传 Excel 文件！");
          this.fileList = [];
          this.faultTwo = true;
          this.faultTwoMsg =
            "上传失败：文件格式错误，请选择后缀名为.xls或.xlsx格式的文件。";
          this.active = 2;
          return;
        }
        this.faultTwo = false;
        this.faultThree = false;
        this.unploadSucc = false;
        this.active = 2;
        this.fileList = [file];
        this.errorUrl = "";
      }
    },
    downloadFile() {
      // //普通考试
      let url1 =
        "https://jzjx-resource.oss-cn-chengdu.aliyuncs.com/analy/template/%E6%96%B0%E9%AB%98%E8%80%83%E8%80%83%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx";
      // //3+1+2
      // let url2 =
      //   "https://jzjx-resource.oss-cn-chengdu.aliyuncs.com/analy/template/%E6%96%B0%E9%AB%98%E8%80%833%2B1%2B2%E8%80%83%E7%94%9F%E6%A8%A1%E6%9D%BF%E5%AF%BC%E5%85%A5.xls";
      // // 3+3
      // let url3 =
      //   "https://jzjx-resource.oss-cn-chengdu.aliyuncs.com/analy/template/%E6%96%B0%E9%AB%98%E8%80%833%2B3%E8%80%83%E7%94%9F%E6%A8%A1%E6%9D%BF.xls";
      let url =
        "https://jzjx-resource.oss-cn-chengdu.aliyuncs.com/analy/template/%E8%80%83%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF(%E6%96%B0).xls";
      // //普通考试
      // if (this.examInformation.examModel == "0") {
      //   url = url1;
      // }
      // //3+3类型
      // if (this.examInformation.examModel == 1) {
      //   url = url3;
      // }
      // //3+1+2类型
      if (this.$parent.examInformation.examModel == 2) {
        url = url1;
      }

      fileDownloadByUrl(url, "考生导入模板");
      this.active = 1;
    },
    goBack() {
      this.dialogVisible = false;
      this.$parent.$refs.ImportWizard.dialogVisible = true;
    },
    init() {
      this.faultTwo = false;
      this.faultThree = false;
      this.unploadSucc = false;
      this.errorUrl = "";
      this.dialogVisible = true;
      this.active = 0;
      this.fileList = [];
    },
  },
};
</script>
<style lang="scss">
@import "@/sass/uploadFile/model.scss";
</style>
<style scoped lang="scss">
.ImportExaminee {
  .import-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .upload-box {
      width: calc(100% - 330px);
    }

    .tips {
      width: 330px;
      height: 425px;
      background: #fff5f0;
      border: 1px dashed #ffb692;
      padding: 10px;
      font-size: 12px;
    }
    > div {
      // height: 300px;
    }
    .export-box-go {
      cursor: pointer;
      color: #0a1119;
      // margin-bottom: 18px;
    }
    .el-steps {
      margin-top: 18px;
    }
  }
}
</style>
