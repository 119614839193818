<template>
  <div class="ImportStudent">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="633px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div>
        <span class="export-box-go" @click="goBack">
          <i class="el-icon-back"></i>
          返回向导页
        </span>
        <div style="margin: 18px 0">
          <el-button type="text" @click="checkClass(1)">全选</el-button>
          <el-button type="text" @click="checkClass(2)">反选</el-button>
          <el-button type="text" @click="checkClass(3)">取消</el-button>
        </div>
        <div class="check-list">
          <el-checkbox-group v-model="classCheckList">
            <el-checkbox
              v-for="(v, i) in classList"
              :key="i"
              :label="v.classNum"
              >{{ v.classNum }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
      <div class="class-box">
        <p>已选班级（人数）：</p>
        <template v-for="(v, i) in classCheckList">
          <span :key="i">
            {{ v }}
            <template v-if="i != classCheckList.length - 1">,</template>
          </span>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goBack">取 消</el-button>
        <el-button
          type="primary"
          :loading="modelLoading"
          @click="submitClassList"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getClassListByLevelAndYear } from "@/core/api/school/schoolgradeclass";
import { saveexamstudentbyclass } from "@/core/api/exam/examstudent";
import { fileDownloadByUrl } from "@/core/util/util";
export default {
  name: "ImportStudent",
  components: {},
  data() {
    return {
      dialogVisible: false,
      classCheckList: [],
      classList: [],
      modelLoading: false,
    };
  },
  created() {},
  methods: {
    submitClassList() {
      // console.log(this.classCheckList);
      let data = {
        classNums: this.classCheckList,
        examId: this.$parent.examInformation.examId,
        level: this.$parent.examInformation.level,
        year: this.$parent.examInformation.year,
        // examInformation
      };
      // data.classNums = this.classCheckList;
      // console.log(data);
      if (this.classCheckList.length == 0) {
        this.$message({
          message: "请先选择班级！",
          type: "warning",
          duration: 1000,
        });
        return;
      }
      this.modelLoading = true;
      saveexamstudentbyclass(data)
        .then((res) => {
          if (res.data.data) {
            // this.$message.warning(res.data.data);
            this.$message({
              message: "当前选择班级存在学生信息有误，已为您下载！",
              type: "warning",
              duration: 1000,
              showClose: true,
            });
            fileDownloadByUrl(res.data.data.url, res.data.data.name);
          } else {
            this.$message({
              message: "成功导入考生！",
              type: "success",
              duration: 1000,
              showClose: true,
            });
          }

          setTimeout(() => {
            this.modelLoading = false;
          }, 500);
          this.dialogVisible = false;
          this.$parent.getDataList();
          this.$parent.getclassandexamroom();
        })
        .catch(() => {
          this.modelLoading = false;
        });
    },
    //获取考试涉及班级
    getClassListByLevelAndYear() {
      let data = {
        level: this.$parent.examInformation.level,
        year: this.$parent.examInformation.year,
      };
      getClassListByLevelAndYear(data).then((res) => {
        this.classList = res.data.data;
        let arr = this.$parent.examInformation.classIds.split(",");
        arr = arr.map((item) => Number(item));
        // console.log(this.classCheckList);
        this.classCheckList = [];
        this.classList.map((item) => {
          if (arr.indexOf(item.id) != -1) {
            this.classCheckList.push(item.classNum);
          }
        });
        // console.log(this.classCheckList);
      });
    },
    checkClass(type) {
      if (type == 1) {
        this.classCheckList = [];
        //全选
        // if (this.checkedClass) {
        this.classList.forEach((index) => {
          this.classCheckList.push(index.classNum);
        });
        // }
      }
      // 反选
      if (type == 2) {
        let arr = [];
        this.classList.forEach((index) => {
          if (
            this.classCheckList.length == 0 ||
            this.classCheckList.indexOf(index.classNum) == -1
          ) {
            arr.push(index.classNum);
          }
        });
        this.classCheckList = arr;
      }
      //取消
      if (type == 3) {
        this.classCheckList = [];
      }
    },
    handleClose(done) {
      this.$parent.$refs.ImportWizard.dialogVisible = true;
      done();
    },
    goBack() {
      this.dialogVisible = false;
      this.$parent.$refs.ImportWizard.dialogVisible = true;
    },
    init() {
      this.dialogVisible = true;
      this.getClassListByLevelAndYear();
    },
  },
};
</script>
<style scoped lang="scss">
.ImportStudent {
  .export-box-go {
    cursor: pointer;
    color: #0a1119;
    // margin-bottom: 18px;
  }
  .el-checkbox-group {
    // display: grid;
    // grid-template-columns: repeat(5, 20%);
    // grid-template-rows: 200px 200px 200px;
    .el-checkbox {
      min-width: 20%;
      margin-right: 0;
    }
  }
  .class-box {
    background: #f9fafc;
    border-radius: 4px;
    padding: 18px;
  }
}
</style>
