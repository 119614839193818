<template>
  <div class="classStatistics">
    <el-dialog
      :title="modelData.title"
      :visible.sync="dialogTableVisible"
      :width="modelData.width"
    >
      <el-table
        v-if="modelData.type == 1"
        :key="tableKey"
        v-loading="listLoading"
        :data="listlength"
        border
        :span-method="arraySpanMethod"
      >
        <el-table-column
          v-for="(v, i) in statisticsData"
          :key="i"
          :label="v.subjectName"
          align="center"
        >
          <el-table-column prop="classNum" label="教学班" align="center">
            <template slot-scope="{ row }">{{
              getClassNum(row, v, "classNum")
            }}</template>
          </el-table-column>
          <el-table-column
            prop="classNumber"
            label="班级人数"
            width="88"
            align="center"
          >
            <template slot-scope="{ row }">{{
              getClassNum(row, v, "number")
            }}</template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-table v-if="modelData.type == 2" :data="subjectClassNumInfo" border>
        <el-table-column
          prop="subjectName"
          label="选科"
          align="center"
          width="79"
        >
        </el-table-column>
        <el-table-column prop="subjectClassNum" label="教学班" align="center">
        </el-table-column>
      </el-table>
      <el-table v-if="modelData.type == 3" :data="studentExamRoomInfo" border>
        <el-table-column
          prop="subjectName"
          label="选科"
          align="center"
          width="79"
        >
        </el-table-column>
        <el-table-column prop="studentClassRoom" label="考场号" align="center">
        </el-table-column>
        <el-table-column prop="studentClassSeat" label="座位号" align="center">
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer"></div>
    </el-dialog>
  </div>
</template>

<script>
import { studentnums } from "@/core/api/exam/examstudent";
//
export default {
  name: "ClassStatistics",
  components: {},
  data() {
    return {
      subjectClassNumInfo: [],
      studentExamRoomInfo: [],
      modelData: {},
      tableKey: 1,
      statisticsData: [
        { subjectName: "历史", subjectId: 4, details: [], total: 0 },
        { subjectName: "物理", subjectId: 8, details: [], total: 0 },
        { subjectName: "地理", subjectId: 5, details: [], total: 0 },
        { subjectName: "政治", subjectId: 6, details: [], total: 0 },
        { subjectName: "化学", subjectId: 9, details: [], total: 0 },
        { subjectName: "生物", subjectId: 7, details: [], total: 0 },
      ],
      dialogTableVisible: false,
      listLoading: false,
      listlength: [],
    };
  },
  created() {},
  methods: {
    getClassNum(row, v, key) {
      let str = "";

      if (row < this.listMax) {
        if (v.details[row]) {
          str = v.details[row][key];
        } else {
          str = "-";
        }
      }
      if (row == this.listMax) {
        str = "选科总人数:" + v.total;
      }
      if (row == this.listMax + 1) {
        // 参考总人数：1234人
        //   let count = 0;

        let count = this.statisticsData.reduce((prev, cur) => {
          return prev + Number(cur.total);
        }, 0);

        str = "参考总人数:" + count / 3;
      }
      return str;
    },

    studentnums() {
      let data = {
        examId: this.examInformation.id,
      };
      this.listLoading = true;
      studentnums(data)
        .then((res) => {
          // let listArr = res.data.data;
          let listArr = [
            { subjectName: "历史", subjectId: 4, details: [], total: 0 },
            { subjectName: "物理", subjectId: 8, details: [], total: 0 },
            { subjectName: "地理", subjectId: 5, details: [], total: 0 },
            { subjectName: "政治", subjectId: 6, details: [], total: 0 },
            { subjectName: "化学", subjectId: 9, details: [], total: 0 },
            { subjectName: "生物", subjectId: 7, details: [], total: 0 },
          ];
          listArr.map((item) => {
            res.data.data.map((index) => {
              if (item.subjectId == index.subjectId) {
                return Object.assign(item, index);
              }
            });
          });
          this.listMax = 0;
          listArr.map((item) => {
            if (this.listMax < item.details.length) {
              this.listMax = item.details.length;
            }
          });
          this.listlength = [];
          for (let i = 0; i < this.listMax + 2; i++) {
            this.listlength.push(i);
          }
          this.tableKey = new Date().getTime();
          this.statisticsData = listArr;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    arraySpanMethod(item) {
      let { rowIndex, columnIndex } = item;
      if (rowIndex == this.listMax) {
        if (columnIndex % 2 == 0) {
          return [1, 2];
        } else if (columnIndex % 2 == 1) {
          return [0, 0];
        }
      }
      if (rowIndex == this.listMax + 1) {
        if (columnIndex === 0) {
          return [1, 12];
        } else {
          return [0, 0];
        }
      }
    },
    init(row, data) {
      if (data.type == 1) {
        this.examInformation = this.$parent.examInformation;
        this.studentnums();
      }
      if (data.type == 2) {
        this.subjectClassNumInfo = row;
      }
      if (data.type == 3) {
        this.studentExamRoomInfo = row;
      }
      this.modelData = data;
      this.dialogTableVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.classStatistics {
}
</style>
