<template>
  <div class="ExamNewStudent">
    <div class="ExamNewStudent-box">
      <detailsHeader
        ref="detailsHeader"
        router-url="examlist"
        :query="{
          examId: $route.query.examId,
        }"
      ></detailsHeader>

      <div v-loading="dataListLoading" class="table-box">
        <div
          v-if="examInformation.id && examInformation.ruleId"
          class="search-criteria"
        >
          <div class="search-item">
            对应状态：
            <el-select
              v-model="search.isMatching"
              filterable
              placeholder="请选择"
              @change="handleClick"
            >
              <el-option
                v-for="(item, i) in searchMatchingList"
                :key="i"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            行政班：
            <el-select
              v-model="search.classNum"
              filterable
              placeholder="请选择"
              @change="handleClick"
            >
              <el-option
                v-for="(item, i) in classNumList"
                :key="i"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            选考组合：
            <el-select
              v-model="search.combination"
              filterable
              placeholder="请选择"
              @change="combinationClick"
            >
              <el-option
                v-for="(item, i) in combinations"
                :key="i"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            选考科目：
            <el-select
              v-model="search.electiveSubjectId"
              filterable
              placeholder="请选择"
              @change="subjuectClick"
            >
              <el-option
                v-for="(item, i) in subjectNames"
                :key="i"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            教学班：
            <el-select
              v-model="search.electiveSubjectClassNum"
              filterable
              placeholder="请选择"
              @change="handleClick"
            >
              <el-option
                v-for="(item, i) in subjectClassNums"
                :key="i"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <el-input
              v-model="search.keyword"
              placeholder="输入姓名/考号"
              @keyup.enter.native="handleClick"
            >
              <i
                slot="suffix"
                class="el-icon-search el-input__icon"
                @click="handleClick"
              >
              </i
            ></el-input>
          </div>
        </div>
        <div v-else class="search-criteria">
          <div class="search-item">
            对应状态：
            <el-select
              v-model="search.isMatching"
              filterable
              placeholder="请选择"
              @change="handleClick"
            >
              <el-option
                v-for="(item, i) in searchMatchingList"
                :key="i"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            班级：
            <el-select
              v-model="search.classNum"
              filterable
              placeholder="请选择"
              @change="handleClick"
            >
              <el-option
                v-for="(item, i) in classNumList"
                :key="i"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            考场：
            <el-select
              v-model="search.studentExamRoom"
              filterable
              placeholder="请选择"
              @change="handleClick"
            >
              <el-option
                v-for="(item, i) in studentExamRoomsList"
                :key="i"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <el-input
              v-model="search.keyword"
              placeholder="输入姓名/考号"
              @keyup.enter.native="handleClick"
            >
              <i
                slot="suffix"
                class="el-icon-search el-input__icon"
                @click="handleClick"
              >
              </i
            ></el-input>
          </div>
        </div>
        <div class="operation-btn">
          <div>
            <el-button
              v-if="examInformation.id && examInformation.ruleId"
              type="primary"
              plain
              @click="showClassStatistics()"
            >
              教学班统计
            </el-button>
          </div>
          <div>
            <el-button
              v-if="status == 1"
              type="primary"
              @click="showAddStudent()"
            >
              <img src="@/assets/newExam/student/icon-add.svg" alt="" />
              <img
                class="on"
                src="@/assets/newExam/student/icon-add.svg"
                alt=""
              />
              添加考生
            </el-button>
            <el-button type="primary" @click="importFile = true">
              <img src="@/assets/newExam/student/icon-export.svg" alt="" />
              <img
                class="on"
                src="@/assets/newExam/student/icon-export.svg"
                alt=""
              />
              导出
            </el-button>
            <el-button v-if="status == 1" type="primary" @click="showExport()">
              <img src="@/assets/newExam/student/icon-import.svg" alt="" />
              <img
                class="on"
                src="@/assets/newExam/student/icon-import.svg"
                alt=""
              />
              导入
            </el-button>
            <el-button type="primary" @click="batch = true">
              <img src="@/assets/newExam/student/icon-verify.svg" alt="" />
              <img
                class="on"
                src="@/assets/newExam/student/icon-verify.svg"
                alt=""
              />
              批量对应
            </el-button>
            <el-button
              v-if="status == 1"
              type="danger"
              @click="showClearStudent"
            >
              <img src="@/assets/newExam/student/icon-clear.svg" alt="" />
              <img
                class="on"
                src="@/assets/newExam/student/icon-clear.svg"
                alt=""
              />
              清除考生
            </el-button>
            <el-button v-if="status == 1" type="primary" @click="showMore()">
              <img src="@/assets/newExam/student/icon-more.png" alt="" />
              <img
                class="on"
                src="@/assets/newExam/student/icon-more.png"
                alt=""
              />
              <!-- <i class="el-icon-more"></i> -->
              更多
            </el-button>
            <!--  -->
          </div>
        </div>
        <div class="tips">
          <!-- <span>!</span
          > -->
          <div>
            注：1、考生对应状态若为“失败”，该考生仍然可以参加考试，也可手动添加该考生至学生信息库；2、若该考生对应状态为“有质疑”，需手动指定考生信息；
          </div>
        </div>
        <el-table
          v-if="examInformation.id"
          ref="multipleTable"
          border
          :data="dataList"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center">
          </el-table-column>
          <el-table-column
            label="学籍号"
            width="200"
            prop="studentNo"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="studentName"
            label="姓名"
            width="120"
          >
          </el-table-column>
          <el-table-column
            v-if="
              examInformation.examType == 1 &&
              $route.name != 'unionExamNewStudent'
            "
            align="center"
            prop="schoolName"
            label="学校"
            width="120"
          >
          </el-table-column>
          <el-table-column align="center" prop="studentExamId" label="考号">
          </el-table-column>
          <el-table-column
            v-if="examInformation.ruleId == 0"
            align="center"
            prop="classNum"
            label="班级"
            width="60"
          >
          </el-table-column>
          <el-table-column
            v-if="examInformation.id && examInformation.ruleId > 0"
            align="center"
            prop="classNum"
            label="行政班"
          >
          </el-table-column>
          <el-table-column align="center" label="学生标记" width="120">
            <template #header>
              <el-tooltip
                class="item"
                effect="dark"
                content="点击下方文字可修改考生对应状态"
                placement="bottom"
              >
                <el-button type="text" class="hover-btn">
                  对应状态
                  <i class="el-icon-warning el-icon--right"></i
                ></el-button>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-button
                type="text"
                :class="{
                  succ: scope.row.isMatching == 0,
                  err: scope.row.isMatching == 1 && scope.row.matchAgain != 1,
                  warning:
                    scope.row.isMatching == 1 && scope.row.matchAgain == 1,
                }"
                @click="showReset(scope.row)"
              >
                <template v-if="scope.row.isMatching == -1">待匹配</template>
                <template v-if="scope.row.isMatching == 0">成功</template>
                <template v-if="scope.row.isMatching == 1">
                  <template v-if="scope.row.matchAgain == 1">有质疑</template>
                  <template v-else>失败</template>
                </template>
                <template v-if="scope.row.isMatching == 2">有质疑</template>
              </el-button>
            </template>
          </el-table-column>
          <!-- 普通考试情况 -->

          <template v-if="examInformation.id && examInformation.ruleId > 0">
            <el-table-column
              align="center"
              prop="combination"
              label="选考组合"
              width="100"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="firstSubjectName"
              label="首选科目"
              width="100"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="secondSubjectName1"
              label="再选科目1"
              width="100"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="secondSubjectName2"
              label="再选科目2"
              width="100"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="studentExamRoom"
              label="教学班"
            >
              <template slot-scope="{ row }">
                <el-button type="text" @click="showClass(row)">详情</el-button>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="studentExamRoom"
              label="考场"
              width="80"
            >
              <template slot-scope="{ row }">
                <el-button type="text" @click="showRoom(row)">详情</el-button>
              </template>
            </el-table-column>
          </template>
          <template v-if="examInformation.id && !examInformation.ruleId">
            <el-table-column
              align="center"
              prop="studentExamRoom"
              label="考场号"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="studentExamSeat"
              label="座位号"
              width="80"
            >
            </el-table-column>
          </template>

          <el-table-column align="center" prop="studentType" label="分类">
            <template slot-scope="{ row }">
              {{ row.studentType ? row.studentType : "-" }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="excludeStatistics"
            label="不参与统计"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.excludeStatistics == 1"> 是 </template>
              <template v-else> 否 </template>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="studentExamSeat"
            label="操作"
            width="114"
          >
            <template slot-scope="scope">
              <template v-if="status == 1">
                <el-button type="text" @click="showAddStudent(scope.row)">
                  修改
                </el-button>
                <el-button
                  type="text"
                  style="color: #ff0000"
                  @click="delStudent(scope.row)"
                >
                  删除
                </el-button>
              </template>
              <template v-else>-</template>
            </template>
          </el-table-column>
        </el-table>
        <no-data
          v-if="total == 0 && dataListLoading == false"
          message="暂无考生信息，请添加"
        ></no-data>
        <el-pagination
          v-if="total > 0"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <div v-if="delStudenttype" class="fd-box">
        <!--浮动里面的盒子-->
        <div class="fd-item">
          <!-- <el-checkbox
            v-model="subCheck"
            class="fd-color"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
            ></el-checkbox
          > -->
          <span class="fd-color"> 选取{{ checkStudentList.length }}项 </span>

          <div class="fd-btn">
            <el-button
              type="text"
              style="color: #ffffff"
              :loading="modelLoading"
              @click="delStudenttype = false"
              >取消</el-button
            >
            <el-button type="danger" @click="deleteSelected">删除</el-button>
          </div>
        </div>
      </div>

      <!-- 清除考生 -->
      <el-dialog
        title="清除考生"
        :visible.sync="dialogVisibleClear"
        width="30%"
        class="class-student-model"
      >
        <div
          v-if="
            examInformation.examType == 0 ||
            $route.name == 'unionExamNewStudent'
          "
        >
          <!-- <p>不选班级将会清除所有考生</p> -->
          <div style="margin-bottom: 12px">
            班级：
            <el-button type="text" @click="checkClass(1)">全选</el-button>
            <el-button type="text" @click="checkClass(2)">反选</el-button>
            <el-button type="text" @click="checkClass(3)">取消</el-button>
            <!-- <el-checkbox v-model="checkedClass" @change="checkClass(1)"
              >全选</el-checkbox
            >
            <el-checkbox v-model="checkedClass1" @change="checkClass(2)"
              >反选</el-checkbox
            >
            <el-checkbox v-model="checkedClass2" @change="checkClass(3)"
              >取消</el-checkbox
            > -->
          </div>
          <div class="check-list">
            <el-checkbox-group v-model="classCheckList">
              <el-checkbox
                v-for="(v, i) in newClassNumList"
                :key="i"
                :label="v.label"
                >{{ v.value }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <useStudentBySchool
          v-else
          ref="useStudentBySchool"
          :list="schoolList"
          @change="useStudentChange"
        />
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleClear = false">取 消</el-button>
          <el-button type="primary" @click="clearStudent">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="温馨提示" :visible.sync="delPassword" width="400px">
        <div>
          此操作将永久删除
          <span v-if="!indexDelObj.studentIds" class="err">
            {{ classCheckList.join(",") }}
          </span>
          <span v-else class="err">
            {{ checkStudentList.map((item) => item.studentName).join(",") }}
          </span>
          等<template v-if="!indexDelObj.studentIds">班级</template>考生,
          <span class="err">若已有扫描记录或已有阅卷记录</span>
          ，请先清空记录后再进行删除！
        </div>
        <div class="password">
          <span>请输入登录密码：</span>
          <el-input
            v-model="password"
            autocomplete="new-password"
            placeholder="请输入登录密码"
            show-password
            @keyup.enter.native="monitorBack"
          ></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="(delPassword = false) && (multipleSelection = [])"
            >取 消</el-button
          >
          <el-button
            type="primary"
            :loading="modelLoading"
            @click="monitorBack()"
          >
            确 定
          </el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 考生导出弹窗 -->
    <el-dialog
      title="考生导出"
      :visible.sync="importFile"
      width="340px"
      class="self-import-form"
    >
      <div style="margin-bottom: 10px">
        请选择导出类型：
        <el-select v-model="isMatch" placeholder="请选择" style="width: 140px">
          <el-option
            v-for="item in matchingExportList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importFile = false"> 取 消 </el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="exportStudent()"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 考生导出弹窗 -->
    <el-dialog
      title="考生导出"
      :visible.sync="importFile"
      width="340px"
      class="self-import-form"
    >
      <div style="margin-bottom: 10px">
        请选择导出类型：
        <el-select v-model="isMatch" placeholder="请选择" style="width: 140px">
          <el-option
            v-for="item in matchingExportList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importFile = false"> 取 消 </el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="exportStudent()"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 批量匹配 -->
    <el-dialog
      title="批量匹配"
      :visible.sync="batch"
      width="372px"
      class="self-import-form"
    >
      <div class="self-import-form-tips">
        请先在基础信息中导入学生, 否则考生信息仍然无法对应，是否继续？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batch = false"> 取 消 </el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="reopenedtostudentbactch()"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 删除单个考生 -->
    <el-dialog title="温馨提示" :visible.sync="delPassword1" width="400px">
      <div>
        此操作将会
        <span class="err">{{ errPassMsg }}</span>
        ，是否继续？
      </div>
      <div class="password">
        <span>请输入登录密码：</span>
        <el-input
          v-model="password"
          autocomplete="new-password"
          placeholder="请输入登录密码"
          show-password
          @keyup.enter.native="monitorBackStu"
        ></el-input>
      </div>
      <!-- <div class="err">{{ errPassMsg }}</div> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="delPassword1 = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="modelLoading"
          @click="monitorBackStu()"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
    <editExaminee ref="editExaminee" :key="componentKey"></editExaminee>
    <showModel ref="showModel" :key="showModelKey"></showModel>
    <ImportWizard ref="ImportWizard"></ImportWizard>
    <ImportExaminee ref="ImportExaminee"></ImportExaminee>
    <ImportModification ref="ImportModification"></ImportModification>
    <reuseExaminee ref="reuseExaminee"></reuseExaminee>
    <ImportStudent ref="ImportStudent"></ImportStudent>
    <!-- <component :is="componentKey"></component> -->
  </div>
</template>

<script>
// getObj
const studentTypeList = [
  {
    label: "所有考生",
    value: 1,
  },
  {
    label: "重学籍号考生",
    value: 2,
  },
  {
    label: "无学籍号考生",
    value: 3,
  },
];
import { getObj, schoollistbyexamid } from "@/core/api/exam/exam";

import useStudentBySchool from "./component/useStudentBySchool.vue";
import editExaminee from "./component/examinee/editExaminee.vue";
import showModel from "./component/examinee/showModel.vue";
import ImportWizard from "./component/examinee/ImportWizard.vue";
import ImportExaminee from "./component/examinee/ImportExaminee.vue";
import ImportModification from "./component/examinee/ImportModification.vue";
import reuseExaminee from "./component/examinee/reuseExaminee.vue";
import ImportStudent from "./component/examinee/ImportStudent.vue";
import detailsHeader from "@/components/detailsHeader.vue";
import {
  fetchList,
  clearAll,
  clearByCondition,
  exportStudent,
  getclassandexamroom,
  reopenedtostudentbactch,
  deleteforpassword,
  failtostudent,
} from "@/core/api/exam/examstudent";
import { getClassListByLevelAndYear } from "@/core/api/school/schoolgradeclass";
import { fileDownloadByUrl, filterSubject, encryption } from "@/core/util/util";
// import {  } from "@/core/util/util";
import { examTypeOptions, matchingList } from "@/core/util/constdata";

export default {
  name: "ExamNewStudent",
  components: {
    useStudentBySchool,
    editExaminee,
    showModel,
    detailsHeader,
    ImportWizard,
    ImportExaminee,
    ImportModification,
    reuseExaminee,
    ImportStudent,
  },
  data() {
    return {
      subjectClassNums: [],
      combinations: [],
      subjectNames: [{ value: "", label: "全部" }],
      electiveSubjectClassNum: "",
      showBtnType: false,
      delPassword1: false,
      errPassMsg: "",
      batch: false,
      isMatch: 1,
      componentKey: 1,
      showModelKey: 2,
      delPassword: false,
      importFile: false,
      indexDelObj: {},
      delBtnLoading: false,
      password: "",
      pendingList: [],
      // 对应考生
      dialogVisibleReset: false,
      matchingList: matchingList,
      searchMatchingList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "成功",
          value: 0,
        },
        {
          label: "失败",
          value: 1,
        },
        {
          label: "有质疑",
          value: 2,
        },
      ],
      matchingExportList: [
        {
          label: "成功",
          value: 0,
        },
        {
          label: "失败",
          value: 5,
        },
        {
          label: "全部",
          value: 1,
        },
      ],
      //清除考生
      dialogVisibleClear: false,
      //使用考生 start
      checkedClass: false,
      checkedClass1: false,
      checkedClass2: false,
      classCheckList: [],
      classList: [],
      // 使用考生 end

      CDN: "https://static.wtjy.com/",
      btnLoading: false,
      //批量修改
      examStudentModifyURL: "",
      modelLoading: false,

      JXList: [],
      //添加修改考生 end
      studentTypeList: studentTypeList,
      //
      input: "",
      value: "",
      options: [],
      //当前考试基本数据
      examInformation: {},
      //表格基本数据
      dataListLoading: false,
      dataList: [],
      //分页 start
      pageIndex: 1,
      pageSize: 50,
      total: 0,
      search: {
        examStudentType: 1,
        classNum: "",
        electiveSubjectClassNum: "",
        electiveSubjectId: "",
        combination: "",
        studentExamRoom: "",
        keyword: "",
        isMatching: "",
      },
      //  classNumList studentExamRoomsList
      classNumList: [],
      newClassNumList: [],
      studentExamRoomsList: [],
      // 分页end
      showEnd: 1,
      typeList: [{ value: "0", label: "全部" }].concat(examTypeOptions),
      clearDataForm: {
        id: 0,
        sort: "",
        selectedItems: [],
        sortIds: [],
        classNums: [],
        classRoomsIds: [],
      },
      //批量删除学生
      checkStudentList: [],
      delStudenttype: false,
      userInfo: {},
      schoolList: [],
      schoolId: [],
      stuKey: 1,
      status: 0,
    };
  },
  async created() {
    this.getDataList();
    this.subjectOptions = filterSubject(this.$route.query.levelId);
    this.getObj();

    this.setJXList();
    this.userInfo = JSON.parse(localStorage.getItem("jzjx-userInfo")).content;
    this.status = this.$route.query.status;
    // this.examStudentImportURL =
    //   "/exam/examstudent/import/" + this.$route.query.examId;
  },
  destroyed() {
    clearTimeout(this.newList);
  },
  methods: {
    useStudentChange(val) {
      this.schoolId = [...val];
    },
    reopenedtostudentbactch() {
      let data = {
        examId: this.$route.query.examId,
      };
      this.btnLoading = true;
      reopenedtostudentbactch(data)
        .then((res) => {
          this.$message({
            message: res.data.data,
            type: "success",
            duration: 5000,
            showClose: true,
          });
          this.getDataList();
          this.btnLoading = false;
          this.batch = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 批量对应考生
    showResetAll() {
      this.$confirm(
        "请先在基础信息中导入学生, 否则考生信息仍然无法对应，是否继续？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "执行中...";
              let data = {
                examId: this.$route.query.examId,
              };
              this.reopenedtostudentbactch(data, done, instance);
            } else {
              done();
            }
          },
        }
      )
        .then(() => {
          this.$message({
            message: "匹配成功！",
            type: "success",
            duration: 1500,
            showClose: true,
          });
          this.getDataList();
        })
        .catch(() => {});
    },
    // 对应考生
    showReset(item) {
      let data = {
        examId: this.$route.query.examId,
      };
      if (item && item.id) {
        data.id = item.id;
      }
      Object.assign(data, this.$route.query);
      this.$router.push({
        name: "ExamMatchTheCandidate",
        query: data,
      });
    },
    goBack() {
      this.$router.push({
        name: "examlist",
        query: { examId: this.$route.query.examId },
      });
    },
    // 显示使用考试弹窗 start
    showClassList() {
      this.classCheckList = [];
      this.checkedClass = false;
      this.checkedClass1 = false;
      this.checkedClass2 = false;
      this.getClassListByLevelAndYear(1);
    },

    //全选  反选 取消
    checkClass(type) {
      if (type == 1) {
        // this.checkedClass1 = false;
        // this.checkedClass2 = false;
        this.classCheckList = [];
        //全选
        // if (this.checkedClass) {
        this.newClassNumList.forEach((index) => {
          this.classCheckList.push(index.value);
        });
        // }
      }
      // 反选
      if (type == 2) {
        this.checkedClass = false;
        this.checkedClass2 = false;
        let arr = [];
        this.newClassNumList.forEach((index) => {
          // console.log(this.classCheckList);
          // console.log(this.classCheckList.indexOf(index.id));
          if (
            this.classCheckList.length == 0 ||
            this.classCheckList.indexOf(index.value) == -1
          ) {
            arr.push(index.value);
          }
        });
        this.classCheckList = arr;
      }
      //取消
      if (type == 3) {
        this.checkedClass1 = false;
        this.checkedClass = false;
        this.classCheckList = [];
      }
      // console.log(this.classCheckList);
    },
    //获取考试涉及班级
    getClassListByLevelAndYear(type) {
      let data = {
        level: this.examInformation.level,
        year: this.examInformation.year,
      };
      getClassListByLevelAndYear(data).then((res) => {
        // console.log(res);
        if (type) {
          this.dialogVisible = true;
        }
        this.classList = res.data.data;
        let arr = this.examInformation.classIds.split(",");
        arr = arr.map((item) => Number(item));
        // console.log(this.classCheckList);
        this.classCheckList = [];
        this.classList.map((item) => {
          if (arr.indexOf(item.id) != -1) {
            this.classCheckList.push(item.classNum);
          }
        });
        // console.log(this.classCheckList);
      });
    },
    //使用考试弹窗 end
    // 导出考生
    exportStudent() {
      this.btnLoading = true;
      let data = {
        examId: this.examInformation.examId,
        isMatch: this.isMatch,
      };
      // console.log(data);
      exportStudent(data)
        .then((response) => {
          let fileUrl = this.CDN + response.data.data.url;
          let fileName = this.examInformation.name + "_考生.xlsx";
          fileDownloadByUrl(fileUrl, fileName);
          this.btnLoading = false;
          this.importFile = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },

    // 批量修盖end
    clearAll() {
      clearAll(this.examInformation.examId)
        .then(() => {
          // this.$message.success("清空成功");
          this.$message({
            message: "清空成功",
            type: "success",
            duration: 3000,
            showClose: true,
          });
          this.modelLoading = false;
          this.dialogVisibleClear = false;
          this.delStudenttype = false;
          this.getDataList();
          this.getclassandexamroom();
        })
        .catch(() => {
          this.modelLoading = false;
          this.dialogVisibleClear = false;
        });
    },
    //删除
    clearByCondition(type) {
      if (type) {
        this.modelLoading = true;
      }
      clearByCondition(this.examInformation.examId, this.indexDelObj)
        .then(() => {
          // this.$message.success("删除成功");
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 3000,
            showClose: true,
          });
          this.modelLoading = false;
          this.dialogVisibleClear = false;
          this.delStudenttype = false;
          this.indexDelObj = {};
          this.delPassword = false;
          this.password = "";
          this.getDataList();
          this.getclassandexamroom();
        })
        .catch(() => {
          this.modelLoading = false;
          this.dialogVisibleClear = false;
        });
    },
    showClearStudent() {
      this.classCheckList = [];
      this.checkedClass = false;
      this.checkedClass1 = false;
      this.checkedClass2 = false;
      this.dialogVisibleClear = true;
      this.getclassandexamroom();
    },
    clearStudent() {
      if (this.classCheckList.length == 0) {
        this.$message({
          message: "请选择班级！",
          type: "warning",
          duration: 3000,
          showClose: true,
        });
        return;
      }
      let data = {};
      if (
        this.examInformation.examType == 0 ||
        this.$route.name == "unionExamNewStudent"
      ) {
        data.classIds = this.classCheckList.join(",");
      } else {
        data.schoolIds = this.schoolId.join(",");
      }
      this.indexDelObj = data;
      this.delPassword = true;
      this.password = "";
      this.dialogVisibleClear = false;
    },
    showExport() {
      // this.$refs.ImportWizard.dialogVisible = true;
      this.$refs.ImportExaminee.dialogVisible = true;
    },
    showMore() {
      this.$refs.ImportWizard.dialogVisible = true;
    },
    failtostudent(password) {
      let data = {
        id: this.indexStudent.id,
        type: 1,
      };
      if (password) {
        data.password = password;
      }
      this.modelLoading = true;
      failtostudent(data)
        .then(() => {
          this.indexStudent = {};
          this.delPassword1 = false;
          this.modelLoading = false;
          this.$message({
            type: "success",
            message: "删除成功!",
            showClose: true,
          });
          this.getDataList();
          this.getclassandexamroom();
        })
        .catch(() => {
          this.modelLoading = false;
        });
    },
    monitorBackStu() {
      if (!this.password) {
        this.$message({
          message: "请输入登录密码",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });

      this.failtostudent(user.password);
    },
    monitorBack() {
      if (!this.password) {
        this.$message({
          message: "请输入登录密码",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      this.indexDelObj.password = user.password;
      this.clearByCondition(1);
    },

    // 是否显示教学班
    getShowList(v) {
      //  firstSubjectId subjuectCheckList
      let subjectArr = [this.addOrUpdateDataForm.firstSubjectId].concat(
        this.subjuectCheckList
      );
      // subjectarr=subjectarr
      // console.log(subjectArr);
      let type = false;
      if (subjectArr.filter((item) => item == v.value).length > 0) {
        type = true;
      }
      // console.log(item);
      // console.log(type);
      return type;
    },
    //教学班默认列表
    setJXList() {
      this.JXList = [];
      let arr = JSON.parse(JSON.stringify(this.subjectOptions));
      arr.forEach((index) => {
        index.classNum = "";
        this.JXList.push(index);
      });
    },
    //考生复用
    dataFormSubmit() {
      this.$refs.editAll.submit();
    },
    //添加  修改考生
    showAddStudent(row) {
      // Object.assign(row.studentExamRoomInfo, row.subjectClassNumInfo);
      this.componentKey = new Date().getTime();
      this.$nextTick(() => {
        this.$refs.editExaminee.init(row);
      });
    },
    //
    showClass(row) {
      let data = {
        width: "362px",
        title: "选科教学班",
        type: 2,
      };
      let { subjectClassNumInfo } = row;
      this.showModelKey = new Date().getTime();
      this.$nextTick(() => {
        this.$refs.showModel.init(subjectClassNumInfo, data);
      });
    },
    showRoom(row) {
      let data = {
        width: "475px",
        title: "考场、座位号",
        type: 3,
      };
      let classData = {
        subjectName: "语数外",
        studentClassRoom: row.studentExamRoom,
        studentClassSeat: row.studentExamSeat,
      };
      let { studentExamRoomInfo } = row;
      this.$nextTick(() => {
        this.$refs.showModel.init(
          [classData].concat(studentExamRoomInfo),
          data
        );
      });
    },
    showClassStatistics(row) {
      let data = {
        width: "1092px",
        title: "教学班人数统计",
        type: 1,
      };
      // if (this.total == 0) {
      //   this.$message({
      //     showClose: true,
      //     message: "暂无考生",
      //     type: "warning",
      //   });
      //   return;
      // }
      this.$nextTick(() => {
        this.$refs.showModel.init(row, data);
      });
    },
    //根据所选科目过滤出科目
    getClassNum(val) {
      let classNum = this.JXList.filter((item) => item.value == val);
      if (classNum.length > 0) {
        classNum = classNum[0].classNum;
      } else {
        classNum = "";
      }
      return classNum;
    },

    // 考生上传end
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getDataList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getDataList();
      // console.log(`当前页: ${val}`);
    },

    //删除多个考生
    deleteSelected() {
      let arr = [];
      this.checkStudentList.map((index) => {
        arr.push(index.studentId);
      });
      this.indexDelObj = {
        studentIds: arr.join(","),
      };
      if (arr.length == 1) {
        this.indexDelObj.studentId = this.indexDelObj.studentIds;
        this.indexDelObj = this.checkStudentList[0];
        this.delStudent(this.indexDelObj);
        return;
      }
      this.password = "";
      this.delPassword = true;
    },
    delStudent(val) {
      this.$confirm("此操作将永久删除当前考生, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.indexDelObj = {
            studentIds: val.studentId,
          };
          this.indexStudent = { id: val.id };
          this.deleteforpassword(val.studentId);
          // let data = {
          //   studentIds: val.studentId,
          // };
          // this.clearByCondition(1);
        })
        .catch(() => {});
    },
    deleteforpassword(studentId) {
      let data = {
        examId: this.examInformation.id,
        studentId: studentId,
      };

      deleteforpassword(data).then((res) => {
        // console.log(res);
        // 需要输入密码
        this.errPassMsg = "";
        if (res.data.data == 0) {
          this.delPassword1 = true;
          this.errPassMsg = res.data.msg;
        }
        // 不需要输入密码
        if (res.data.data == 1) {
          this.failtostudent();
          // this.clearByCondition(1);
        }
      });
    },
    handleSelectionChange(e) {
      // console.log(e);
      this.checkStudentList = e;
      if (this.checkStudentList.length > 0) {
        this.delStudenttype = true;
      } else {
        this.delStudenttype = false;
      }
    },
    combinationClick() {
      if (!this.oldSubjectNames) {
        this.oldSubjectNames = JSON.parse(JSON.stringify(this.subjectNames));
      }
      let combination = this.search.combination.split("");
      let array = [];
      this.subjectOptions.map((item) => {
        if (combination.indexOf(item.abbreviation) != -1) {
          array.push(item);
        }
      });
      this.subjectNames = [{ value: "", label: "全部" }].concat(array);

      if (combination.length == 0) {
        this.subjectNames = JSON.parse(JSON.stringify(this.oldSubjectNames));
      }
      if (this.search.electiveSubjectId) {
        let classFilter = this.subjectNames.filter(
          (item) => item.value == this.search.electiveSubjectId
        );
        if (classFilter.length == 0) {
          this.search.electiveSubjectId = "";
        }
      }
      this.subjuectClick();
    },
    subjuectClick() {
      // this.pageIndex = 1;
      // if (!this.oldSubjectClassNums) {
      //   this.oldSubjectClassNums = JSON.parse(
      //     JSON.stringify(this.subjectClassNums)
      //   );
      // }
      // const combination = this.search.combination.split("");

      // let array = [];
      // // 判断是否有选考组合
      // if (combination.length == 0) {
      //   this.subjectClassNums = JSON.parse(
      //     JSON.stringify(this.oldSubjectClassNums)
      //   );
      // } else {
      //   combination.map((item) => {
      //     this.oldSubjectClassNums.map((index) => {
      //       if (index.label.indexOf(item) != -1) {
      //         array.push(index);
      //       }
      //     });
      //     this.subjectClassNums = [{ value: "", label: "全部" }].concat(array);
      //   });
      // }
      // // 判断是否有选考科目
      // if (this.search.electiveSubjectId) {
      //   let subjuectName = this.subjectOptions.filter(
      //     (item) => item.value == this.search.electiveSubjectId
      //   )[0].abbreviation;
      //   this.subjectClassNums = this.subjectClassNums.filter(
      //     (item) => item.label.indexOf(subjuectName) != -1
      //   );
      //   this.subjectClassNums = [{ value: "", label: "全部" }].concat(
      //     this.subjectClassNums
      //   );
      // }
      // if (this.search.electiveSubjectClassNum) {
      //   let classFilter = this.subjectClassNums.filter(
      //     (item) => item.value == this.search.electiveSubjectClassNum
      //   );
      //   if (classFilter.length == 0) {
      //     this.search.electiveSubjectClassNum = "";
      //   }
      // }
      this.handleClick();
    },
    handleClick() {
      this.pageIndex = 1;
      this.getDataList();
    },
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            //第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    //获取学生列表
    getDataList(val) {
      // console.log(new Date());
      if (!val) this.dataListLoading = true;
      fetchList(
        Object.assign(
          {
            current: this.pageIndex,
            size: this.pageSize,
            examId: this.$route.query.examId,
          },
          this.search
        )
      )
        .then((response) => {
          this.dataListLoading = false;

          this.dataList = response.data.data.records;
          this.showEnd = new Date().getTime();
          this.dataList.forEach((i) => {
            i.isCheck = false;
            this.clearDataForm.classNums.push(i.classNum);
            this.clearDataForm.classRoomsIds.push(i.studentExamRoom);
          });
          this.total = response.data.data.total;
          //所有班级--去重排序
          this.clearDataForm.classNums = this.unique(
            this.clearDataForm.classNums
          );
          this.clearDataForm.classNums = this.clearDataForm.classNums.sort(
            (old, New) => {
              return old - New;
            }
          );
          //所有类别
          //所有考场
          this.clearDataForm.classRoomsIds = this.unique(
            this.clearDataForm.classRoomsIds
          );
          this.clearDataForm.classRoomsIds =
            this.clearDataForm.classRoomsIds.sort((old, New) => {
              return old - New;
            });
          if (this.count == response.data.data.total) {
            this.count = 0;
          }

          if (this.count > 0 && this.count != response.data.data.total) {
            this.newList = setTimeout(() => {
              this.getDataList(1);
              this.getclassandexamroom();
            }, 2000);
          }
        })
        .catch(() => {
          this.dataListLoading = false;
        });
    },
    async getExamSchools() {
      const res = await schoollistbyexamid({
        examId: this.$route.query.examId,
      });
      this.schoolList = res.data.data;
    },
    //获取考试基本信息
    getObj() {
      this.examStudentModifyURL =
        "/exam/examstudent/modify/" + this.$route.query.examId;
      this.examStudentImportURL =
        "/exam/examstudent/import/" + this.$route.query.examId;
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        // this.$refs.detailsHeader.init(res.data.data);
        //获取当前年级班级
        this.status = this.examInformation.status;
        // this.getClassListByLevelAndYear();
        if (res.data.data.examType == 1) {
          // 当考试为联考时 获取 当前主管单位下的学校列表
          this.getExamSchools();
        }
        this.getclassandexamroom();
        // console.log(res);
      });
    },
    getclassandexamroom() {
      getclassandexamroom(this.$route.query.examId).then((res) => {
        // console.log(res);
        this.classNumList = [{ value: "", label: "全部" }];
        this.newClassNumList = [];
        this.studentExamRoomsList = [{ value: "", label: "全部" }];
        res.data.data.classNums.forEach((index) => {
          this.classNumList.push({
            value: index,
            label: index,
          });
          this.newClassNumList.push({
            value: index,
            label: index,
          });
        });
        this.subjectClassNums = [{ value: "", label: "全部" }];
        this.combinations = [{ value: "", label: "全部" }];
        res.data.data.studentExamRooms.forEach((index) => {
          this.studentExamRoomsList.push({
            value: index,
            label: index,
          });
        });
        if (this.examInformation.ruleId) {
          let { subjectClassNums, combinations, subjectNames } = res.data.data;
          subjectClassNums.map((item) => {
            this.subjectClassNums.push({
              value: item,
              label: item,
            });
          });
          combinations.map((item) => {
            this.combinations.push({
              value: item,
              label: item,
            });
          });
          // this.subjectNames = [{ value: "", label: "全部" }];
          this.subjectNames = this.subjectOptions.filter(
            (item) => subjectNames.indexOf(item.value) != -1
          );
          this.subjectNames = [{ value: "", label: "全部" }].concat(
            this.subjectNames
          );
        }

        // subjectClassNums

        // this.studentExamRoomsList = res.data.data.studentExamRooms;
      });
    },
  },
};
</script>
<style lang="scss">
.student-reuse {
  .el-select {
    width: 100%;

    &:first-child {
      margin-bottom: 24px;
    }
  }
}
.ExamNewStudent {
  .class-item {
    display: flex;
    align-items: center;
    .el-input {
      width: 140px;
      line-height: 25px;
      .el-input__inner {
        height: 25px;
        line-height: 25px;
        margin-left: 8px;
      }
    }
  }
  .self-add-form {
    .el-form-item__label {
      // width: 100px !important;
    }
    .el-form-item__content {
      // margin-left: 100px !important;
    }
    .add-student-info {
      display: flex;
      justify-content: space-between;
      .el-form-item {
        width: 49%;
      }
    }
  }
  //弹窗
  .el-dialog {
    // height: 372px;
    .el-link--inner {
      color: $primary-color;
    }
    // i {
    //   color: $primary-color;
    // }
    .upload-file {
      width: 560px;
      // height: 88px;
      .el-upload__text {
        color: #606266;
        font-size: 14px;
        align-items: center;
        margin: 0 auto;
      }
      .el-upload {
        width: 560px !important;
        // height: 88px !important;

        .el-upload-dragger {
          width: 560px !important;
          // height: 88px !important;
          // margin: 24px 0;
          border-radius: 4px;
          border: dashed 1px #bdbdbd;
          // line-height: 88px;
        }
      }
    }
  }

  //使用考生
  .class-student-model {
    .check-list {
      padding-left: 50px;
    }
  }
  // 批量管理弹窗
  .self-amending-form {
    .upload-tips {
      width: 541px;
      // font-family: PingFangSC;
      font-size: 14px;
      line-height: normal;
      letter-spacing: normal;
      text-align: justify;
      color: rgba(0, 0, 0, 0.85);
      // margin-left: 22px;
      margin-bottom: 10px;

      span {
        // background-color: #fdd0b3;
        color: $primary-color;
      }
    }
  }
  .el-table__header-wrapper {
    th {
      background-color: #f2f5f8;
    }
  }
}
</style>
<style lang="scss" scoped>
.ExamNewStudent {
  background: #f4f4f4;
  min-height: calc(100vh - 152px);
  ::v-deep .el-table__empty-block {
    display: none;
  }
  .self-import-form-tips {
    background: #fff4f3;
    border: 1px dashed #ff9495;
    padding: 12px;
    margin-bottom: 14px;
  }

  .password {
    margin-top: 12px;
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
    }
  }
  // ::v-deep
  .succ {
    color: $primary-color;
  }
  .warning {
    color: #e6a23b;
  }
  .err {
    color: #ff0000;
  }
  ::v-deep .hover-btn {
    span {
      color: #101011;
      font-weight: bold;
    }
    i {
      color: #2474ed;
    }
  }
  .fd-box {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding: 12px 0;
    width: 100%;
    background-color: rgba(33, 33, 33, 0.5);
    /*.*/

    .fd-item {
      width: 1220px;
      margin: 0 auto;
      padding: 0 73px 0 37px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .fd-color {
        color: #ffffff;
        // .el-checkbox__label {

        // }
      }
    }
  }
  .ExamNewStudent-box {
    // width: 1720px;
    // margin: 0 auto;
    padding: 24px 0 60px;
    .operation-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
      background: #f9fafc;
      border-radius: 4px;
      padding: 12px 18px;
      .el-button {
        line-height: 16px;
      }
    }
    .search-criteria {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .search-item {
        margin-bottom: 18px;
        margin-right: 24px;
        .el-select {
          width: 140px;
        }
      }

      .item-width {
        .el-select {
          width: 100px;
        }
      }
    }
    .tips {
      padding: 10px 10px 10px 34px;
      // margin-top: 24px;
      background: #fdf0ee;
      // border: 1px dashed #ff7575;
      color: #292626;
      margin-bottom: 24px;
      div {
        line-height: 14px;
        // margin-bottom: 12px;
        position: relative;
        &::after {
          content: "!";
          position: absolute;
          letter-spacing: 0;
          width: 13px;
          height: 13px;
          text-align: center;
          line-height: 13px;
          border-radius: 50%;
          background-color: #f17e5e;
          left: -21px;
          font-size: 12px;
          top: 0.5;
          color: #ffffff;
        }
      }
      p {
        margin-bottom: 12px;
      }
    }
    .table-box {
      background-color: #ffffff;
      padding: 18px;
      margin-top: 24px;
    }
    .operation-box {
      background-color: #ffffff;
      padding: 24px 78px 0 24px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 24px;
      position: relative;
      .edit-btn {
        position: absolute;
        right: 24px;
        top: 24px;
      }
      .item-box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .el-button {
        margin-bottom: 24px;
      }
      .el-select {
        width: 110px;
        height: 32px;
      }
      .el-input {
        width: 140px;
      }
      .search-item {
        font-size: 14px;
        margin-right: 8px;
        margin-bottom: 24px;
        .el-icon-search {
          cursor: pointer;
        }
        &:last-child {
          margin-right: 0;
        }
        // width: 100;
      }
    }
    .header-box {
      background-color: #ffffff;
      padding: 24px 24px 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .el-select {
        width: 112px;
        height: 32px;
      }
      .el-input {
        width: 250px;
      }
      // .el-button {
      //   padding-left: 0.8vw;
      //   padding-right: 0.8vw;
      // }
      .btn-list-box {
        .el-button {
          margin-bottom: 24px;
        }
      }
      .header-search-box {
        font-weight: 400;
        display: flex;
        // margin-right: 40px;
        flex-shrink: 0;
        flex-wrap: wrap;
        // width: 50%;
        .search-item {
          font-size: 16px;
          margin-right: 24px;
          margin-bottom: 24px;
          .el-icon-search {
            cursor: pointer;
          }
          &:last-child {
            margin-right: 0;
          }
          // width: 100;
        }
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #080a09;
        margin-bottom: 24px;
        width: 100%;
        cursor: pointer;
      }
    }
  }
}
</style>
