<template>
  <div class="examinee">
    <!-- 添加/修改考生弹窗 -->
    <el-dialog
      :title="!addOrUpdateDataForm.id ? '添加考生' : '修改考生'"
      :visible.sync="addOrUpgradeStudentVisible"
      width="559px"
    >
      <el-form
        :key="stuKey"
        ref="addOrUpdateDataForm"
        :model="addOrUpdateDataForm"
        :rules="addOrUpdateDataRule"
        label-width="95px"
        class="self-add-form"
      >
        <el-form-item
          v-if="
            examInformation.examType == 1 &&
            $route.name != 'unionExamNewStudent'
          "
          label="学校:"
          prop="schoolId"
        >
          <el-select
            v-model="addOrUpdateDataForm.schoolId"
            filterable
            placeholder="请选择"
            style="width: 100%"
            :disabled="addOrUpdateDataForm.id > 0"
          >
            <el-option
              v-for="item in schoolList"
              :key="item.schoolId"
              :label="item.schoolName"
              :value="item.schoolId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="add-student-info">
          <el-form-item label="姓名:" prop="studentName">
            <el-input v-model="addOrUpdateDataForm.studentName"></el-input>
          </el-form-item>
          <el-form-item label="学籍号:">
            <el-input v-model="addOrUpdateDataForm.studentNo"></el-input>
          </el-form-item>
        </div>
        <div class="add-student-info">
          <el-form-item
            :label="examInformation.examModel ? '行政班:' : '班级:'"
            prop="classNum"
          >
            <el-input v-model="addOrUpdateDataForm.classNum"></el-input>
          </el-form-item>
          <el-form-item label="考号:" prop="studentExamId">
            <el-input
              v-model="addOrUpdateDataForm.studentExamId"
              placeholder=""
            ></el-input>
          </el-form-item>
        </div>
        <!-- {{ examInformation.examModel }} -->
        <!-- 普通考试 -->
        <div class="add-student-info">
          <el-form-item label="考场号:">
            <el-input v-model="addOrUpdateDataForm.studentExamRoom"></el-input>
          </el-form-item>
          <el-form-item label="座位号:">
            <el-input v-model="addOrUpdateDataForm.studentExamSeat"></el-input>
          </el-form-item>
        </div>

        <!-- 3+1+2 -->
        <div v-if="examInformation.examModel == 2">
          <el-form-item label="首选科目:" required style="margin-bottom: 0">
          </el-form-item>
          <el-form-item label="" prop="firstSubjectId" class="subject-list">
            <div
              v-for="(item, index) in firstChoiceSubject"
              :key="index"
              class="subject-list-box"
            >
              <el-radio v-model="firstSubjectId" :label="item.value">
                {{ item.label }}
              </el-radio>
              <template v-if="item.value == firstSubjectId">
                <el-input
                  v-model="item.subjectClassNum"
                  class="class-name"
                  placeholder="填写教学班信息"
                  :disabled="getSubDis(item, 0)"
                ></el-input>
                <el-input
                  v-model="item.studentClassRoom"
                  class="exam-id"
                  :disabled="getSubDis(item, 0)"
                  placeholder="考场号"
                ></el-input>
                <el-input
                  v-model="item.studentClassSeat"
                  class="seat-id"
                  :disabled="getSubDis(item, 0)"
                  placeholder="座位号"
                ></el-input>
              </template>
              <template v-else>
                <el-input
                  class="class-name"
                  placeholder="填写教学班信息"
                  :disabled="getSubDis(item, 0)"
                ></el-input>
                <el-input
                  class="exam-id"
                  :disabled="getSubDis(item, 0)"
                  placeholder="考场号"
                ></el-input>
                <el-input
                  class="seat-id"
                  :disabled="getSubDis(item, 0)"
                  placeholder="座位号"
                ></el-input>
              </template>
            </div>
          </el-form-item>
          <el-form-item
            label="再选科目（可多选且最多选择两科）："
            required
            style="margin-bottom: 0"
            class="item-sub"
          >
          </el-form-item>
          <el-form-item class="subject-list" prop="subjectList">
            <div
              v-for="(item, index) in rechooseSubject"
              :key="index"
              class="subject-list-box"
            >
              <el-checkbox-group v-model="subjectList">
                <el-checkbox
                  :label="item.value"
                  :disabled="getCheckBoxDis(item, 2)"
                >
                  {{ item.label }}</el-checkbox
                >
              </el-checkbox-group>
              <template v-if="subjectList.indexOf(item.value) != -1">
                <el-input
                  v-model="item.subjectClassNum"
                  class="class-name"
                  placeholder="填写教学班信息"
                  :class="{ subject_class_num_on: item.subjectClassNum != '' }"
                ></el-input>
                <el-input
                  v-model="item.studentClassRoom"
                  class="exam-id"
                  placeholder="考场号"
                ></el-input>
                <el-input
                  v-model="item.studentClassSeat"
                  class="seat-id"
                  placeholder="座位号"
                ></el-input>
              </template>
              <template v-else>
                <el-input
                  class="class-name"
                  placeholder="填写教学班信息"
                  :disabled="getSubDis(item, 0)"
                ></el-input>
                <el-input
                  class="exam-id"
                  :disabled="getSubDis(item, 0)"
                  placeholder="考场号"
                ></el-input>
                <el-input
                  class="seat-id"
                  :disabled="getSubDis(item, 0)"
                  placeholder="座位号"
                ></el-input>
              </template>
            </div>
          </el-form-item>
        </div>
        <!-- 3+3 -->
        <!-- <template
            v-if="examInformation.examModel == 1"
            class="add-student-info"
          >
            <el-form-item label="选考科目">
              <el-checkbox-group v-model="subjuectCheckList">
                <el-checkbox :label="6">政治</el-checkbox>
                <el-checkbox :label="4">历史</el-checkbox>
                <el-checkbox :label="5">地理</el-checkbox>
                <el-checkbox :label="8">物理</el-checkbox>
                <el-checkbox :label="9">化学</el-checkbox>
                <el-checkbox :label="7">生物</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </template> -->
        <!-- <p>{{ addOrUpdateDataForm.studentType }}</p> -->
        <el-form-item label="不参与统计:" prop="excludeStatistics">
          <el-radio v-model="addOrUpdateDataForm.excludeStatistics" label="1"
            >是</el-radio
          >
          <el-radio v-model="addOrUpdateDataForm.excludeStatistics" label="0"
            >否</el-radio
          >
        </el-form-item>
        <div class="add-student-info">
          <el-form-item label="分类:" prop="studentType">
            <el-input v-model="addOrUpdateDataForm.studentType"></el-input>
          </el-form-item>
        </div>

        <!-- <el-form-item
            v-if="examInformation.examModel > 0"
            label="教学班详情"
            prop="studentType"
          >
            <template v-for="(v, i) in JXList">
              <div v-if="getShowList(v)" :key="i" class="class-item">
                {{ v.label }}：
                <el-input
                  v-model="v.classNum"
                  placeholder="请输入教学班"
                ></el-input>
              </div>
            </template> -->
        <!-- <el-input v-model="addOrUpdateDataForm.studentType"></el-input> -->
        <!-- </el-form-item> -->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :loading="modelLoading"
          @click="studentAddFormSubmit()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  putObj,
  addObj,
  examstudentBeforeedit,
} from "@/core/api/exam/examstudent";

const firstChoiceSubject = [
  {
    value: 4,
    label: "历史",
    studentClassRoom: "",
    studentClassSeat: "",
    subjectClassNum: "",
  },
  {
    value: 8,
    label: "物理",
    studentClassRoom: "",
    studentClassSeat: "",
    subjectClassNum: "",
  },
];

const rechooseSubject = [
  {
    value: 5,
    label: "地理",
    studentClassRoom: "",
    studentClassSeat: "",
    subjectClassNum: "",
  },
  {
    value: 6,
    label: "政治",
    studentClassRoom: "",
    studentClassSeat: "",
    subjectClassNum: "",
  },

  {
    value: 9,
    label: "化学",
    studentClassRoom: "",
    studentClassSeat: "",
    subjectClassNum: "",
  },
  {
    value: 7,
    label: "生物",
    studentClassRoom: "",
    studentClassSeat: "",
    subjectClassNum: "",
  },
];
export default {
  name: "Examinee",
  components: {},
  data() {
    var firstSubjectIdFn = (rule, value, callback) => {
      //   console.log(rule);
      let data = this.firstChoiceSubject.filter(
        (item) => item.value == this.firstSubjectId
      )[0];

      if (!data || !data.value) {
        return callback(new Error("请选择首选科目"));
      }
      if (!data.subjectClassNum) {
        return callback(new Error("请输入首选科目教学班"));
      }
      callback();
    };
    var subjectList = (rule, value, callback) => {
      let { examModel } = this.examInformation;
      if (this.subjectList.length != examModel) {
        return callback(new Error(`请选择${examModel}个再选科目`));
      }
      let type = false;
      this.rechooseSubject.map((item) => {
        if (this.subjectList.indexOf(item.value) != -1) {
          if (!item.subjectClassNum) {
            type = true;
          }
        }
      });
      if (type == true) {
        return callback(new Error(`请填写所选教学班班级`));
      }
      callback();
    };
    return {
      stuKey: 1,
      firstChoiceSubject: JSON.parse(JSON.stringify(firstChoiceSubject)),
      rechooseSubject: JSON.parse(JSON.stringify(rechooseSubject)),
      examInformation: {},
      subjectList: [],
      firstSubjectId: "",
      //添加 修改考生 start
      addOrUpdateDataForm: {
        studentId: "",
        studentName: "",
        classNum: "",
        studentNo: "",
        studentExamId: "",
        studentClassRoom: "",
        studentClassSeat: "",
        comment: "",
        firstSubjectId: "",
        excludeStatistics: "0",
      },
      addOrUpdateDataRule: {
        studentName: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        studentNo: [
          { required: true, message: "学籍号不能为空", trigger: "blur" },
        ],
        classNum: [
          { required: true, message: "班级不能为空", trigger: "blur" },
        ],
        studentExamId: [
          { required: true, message: "考号不能为空", trigger: "blur" },
        ],
        studentClassRoom: [
          { required: true, message: "考场号不能为空", trigger: "blur" },
        ],
        studentClassSeat: [
          { required: true, message: "座位号不能为空", trigger: "blur" },
        ],
        schoolId: [
          { required: true, message: "请选择学校", trigger: "change" },
        ],
        firstSubjectId: [{ validator: firstSubjectIdFn, trigger: "change" }],
        subjectList: [{ validator: subjectList, trigger: "change" }],
      },
      addOrUpgradeStudentVisible: false,
      modelLoading: false,
    };
  },
  created() {},
  methods: {
    // 禁用多选框 type等于subjectList 长度就禁用其他项
    getCheckBoxDis(item, type) {
      let returnType = false;
      if (type == this.subjectList.length) {
        returnType = true;
        if (this.subjectList.indexOf(item.value) != -1) {
          returnType = false;
        }
      }
      return returnType;
    },
    getSubDis(item) {
      if (item.value != this.firstSubjectId) {
        return true;
      }
      return false;
    },

    //编辑  添加考生
    studentAddFormSubmit() {
      this.addOrUpdateDataForm.examId = this.$route.query.examId;
      this.addOrUpdateDataForm.ruleId = this.examInformation.ruleId;
      this.modelLoading = true;
      this.$refs["addOrUpdateDataForm"].validate((valid) => {
        if (valid) {
          // 3+2+1 再选科目不能大于两科
          // if (
          //   this.examInformation.examModel == 2 &&
          //   this.subjuectCheckList.length > 2
          // ) {
          //   this.$message({
          //     message: "‘3+1+2’ 模式再选科目不能大于两科",
          //     type: "warning",
          //   });
          //   return;
          // }
          // // 3+3 再选科目不能大于三科
          // if (
          //   this.examInformation.examModel == 1 &&
          //   this.subjuectCheckList.length > 3
          // ) {
          //   this.$message({
          //     message: "‘3+3’ 模式再选科目不能大于三科",
          //     type: "warning",
          //   });
          //   return;
          // }
          let data = JSON.parse(JSON.stringify(this.addOrUpdateDataForm));

          //判断当前类型为3+1+2模式 数据整合提交
          // firstClassNum	首选科目教学班		false
          // firstExamRoom
          // firstExamSeat
          if (this.examInformation.examModel == 2) {
            //3+2+1有首选科目;
            // 首选科目添加
            data.firstSubjectId = this.firstSubjectId;
            let subject = this.firstChoiceSubject.filter(
              (item) => item.value == this.firstSubjectId
            )[0];
            data.firstClassNum = subject.subjectClassNum;
            data.firstExamSeat = subject.studentClassSeat;
            data.firstExamRoom = subject.studentClassRoom;
            // 再选科目添加
            let rechooseSubject = this.rechooseSubject.filter(
              (item) => this.subjectList.indexOf(item.value) != -1
            );
            rechooseSubject.map((item, index) => {
              let secondSubjectIdstr = "secondSubjectId" + (index + 1);
              let secondExamSeatstr = "secondExamSeat" + (index + 1);
              let secondExamRoomstr = "secondExamRoom" + (index + 1);
              let secondClassNumstr = "secondClassNum" + (index + 1);
              data[secondSubjectIdstr] = item.value;
              data[secondExamSeatstr] = item.studentClassSeat;
              data[secondExamRoomstr] = item.studentClassRoom;
              data[secondClassNumstr] = item.subjectClassNum;
            });
          }
          if (this.examInformation.examModel == 1) {
            // this.setOptVal(3);
          }
          // if (data) {
          //   console.log(data);
          //   return;
          // }

          this.modelLoading = true;
          if (this.addOrUpdateDataForm.id) {
            if (this.examInformation.ruleId > 0) {
              this.setExamstudentBeforeedit(data);
            } else {
              this.editStudent(data);
            }
          } else {
            addObj(data)
              .then(() => {
                this.$message.success("添加成功");
                this.addOrUpgradeStudentVisible = false;
                setTimeout(() => {
                  this.modelLoading = false;
                }, 500);
                this.$parent.getDataList();
              })
              .catch(() => {
                // this.addOrUpgradeStudentVisible = false;
                this.modelLoading = false;
              });
          }
        } else {
          this.modelLoading = false;
        }
      });
    },
    editStudent(data, instance, done) {
      putObj(data)
        .then(() => {
          this.$message.success("修改成功");
          this.addOrUpgradeStudentVisible = false;
          setTimeout(() => {
            this.modelLoading = false;
          }, 500);
          this.$parent.getDataList();
          if (data.examPaperIds) {
            done();
            instance.confirmButtonLoading = false;
          }
        })
        .catch(() => {
          this.modelLoading = false;
          if (data.examPaperIds) {
            instance.confirmButtonLoading = false;
          }
        });
    },
    // 验证信息
    setExamstudentBeforeedit(data) {
      examstudentBeforeedit(data).then((res) => {
        // console.log(res);
        if (res.data.data) {
          const h = this.$createElement;
          this.$msgbox({
            title: "温馨提示",
            message: h("p", null, [
              h("span", null, "当前考生已经扫入修改科目的答题卡，继续修改会"),
              h("span", { style: "color: red" }, "删除答题卡并且删除打分记录"),
              h("span", null, "，是否继续? "),
            ]),
            showCancelButton: true,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            beforeClose: (action, instance, done) => {
              if (action === "confirm") {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = "执行中...";
                data.examPaperIds = res.data.data;
                this.editStudent(data, instance, done);
              } else {
                done();
              }
            },
          })
            .then(() => {
              // this.$message({
              //   type: 'success',
              //   message: '删除成功!'
              // });
            })
            .catch(() => {
              this.modelLoading = false;
            });
        } else {
          this.editStudent(data);
        }
      });
    },
    //添加  修改考生
    init(row) {
      this.examInformation = this.$parent.examInformation;
      this.stuKey = new Date().getTime();
      this.subjuectCheckList = [];
      if (row) {
        // 3+3
        // 给科目赋值
        let { subjectClassNumInfo, studentExamRoomInfo } = row;
        if (this.examInformation.examModel > 0) {
          // console.log(subjectClassNumInfo);
          subjectClassNumInfo.map((item) => {
            studentExamRoomInfo.map((index) => {
              if (item.subjectId == index.subjectId) {
                Object.assign(item, index);
              }
            });
          });
          //
        }
        // 3+2+1

        if (this.examInformation.examModel == 2) {
          this.firstChoiceSubject.map((item) => {
            subjectClassNumInfo.map((index) => {
              if (item.value == index.subjectId) {
                this.firstSubjectId = item.value;
                Object.assign(item, index);
              }
            });
          });
          this.subjectList = [];
          this.rechooseSubject.map((item) => {
            subjectClassNumInfo.map((index) => {
              if (item.value == index.subjectId) {
                // this.firstSubjectId = item.value;
                this.subjectList.push(item.value);
                Object.assign(item, index);
              }
            });
          });
        }
        this.addOrUpdateDataForm = Object.assign(this.addOrUpdateDataForm, row);
        this.addOrUpdateDataForm.studentType =
          this.addOrUpdateDataForm.studentType || "";
        // 给科目班级
      } else {
        this.addOrUpdateDataForm = this.$options.data().addOrUpdateDataForm;
      }
      this.addOrUpdateDataForm = Object.assign({}, this.addOrUpdateDataForm);
      this.addOrUpgradeStudentVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.examinee {
  .el-form-item {
    margin-bottom: 2px;
    ::v-deep .el-form-item__error {
      margin-top: 0;
      padding-top: 0px;
      top: auto;
    }
  }
  .item-sub {
    ::v-deep .el-form-item__label {
      width: 290px !important;
    }
  }
  .subject-list {
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
      //   display: flex;
    }

    .subject-list-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 32px;
      margin-bottom: 18px;
      .class-name {
        width: 180px;
      }
      .subject_class_num_on {
        ::v-deep .el-input__inner {
          border-color: #dcdfe6 !important;
        }
      }
      .seat-id {
        width: 98px;
        ::v-deep .el-input__inner {
          border-color: #dcdfe6 !important;
        }
      }
      .exam-id {
        width: 120px;
        ::v-deep .el-input__inner {
          border-color: #dcdfe6 !important;
        }
      }
      .el-radio {
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }
}
</style>
